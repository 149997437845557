$(document).ready(function() {
    const $header = $('.header');
    const $headerToggler = $header.find('.header__toggler');
    const $headerMenuItems = $header.find('.header__menu-item');

    // events
    $headerToggler.on('click', onTogglerClick);
    $headerMenuItems.on('click', onLinkClick);
    if($header && $header.hasClass('header_fixed')) {
        document.addEventListener('scroll', onScroll);
    }

    function onScroll() {
        window.scrollY > 0 ? $header.addClass('header_fixed-active') :
            $header.removeClass('header_fixed-active');
    }

    function onTogglerClick() {
        if($headerToggler.hasClass('collapsed')) {
            setTimeout(() => {
                $header.removeClass('header_show-collapsed-menu');
            }, 300);
        } else {
            $header.addClass('header_show-collapsed-menu');
        }
    }

    function onLinkClick() {
        if(!$headerToggler.hasClass('collapsed')) {
            $headerToggler.click();
            setTimeout(() => {
                onTogglerClick();
            })
        }
    }
});
